<script setup>
 const props = defineProps({
  image: {
   type: [Object, String],
   required: false,
  },
  videoOptions: {
   type: Object,
   required: false,
  },
  headline: {
   type: String,
   required: false,
  },
  copy: {
   type: String,
   required: false,
  },
  button: {
   type: Object,
   required: false,
  },
  verticalText: {
   type: String,
   required: false,
  },
  attributes: {
   type: Object,
   default: () => ({}),
  },
  blockIndex: {
   type: Number,
   required: false,
  },
 });
 const { image, videoOptions, headline, copy, button, verticalText } = toRefs(props);
 const videoPlaying = ref(videoOptions?.value?.embed);
</script>

<template>
 <section class="block block-hero bg-gray-100" tabindex="-1">
  <div class="container server-v-aos">
   <div class="grid">
    <h1 class="title">{{ headline }}</h1>
    <div class="media">
     <MessImage
      v-if="image?.src && !videoOptions?.embed"
      :image="image"
      :lazy="false"
      class="hero-image"
      :height="525"
      :width="756"
     />
     <div class="video-wrapper">
      <FragmentsVideosYoutube v-if="videoOptions?.embed" :options="videoOptions" />
     </div>
     <FragmentsHoursStatusMessage v-if="!videoOptions?.addPlayFullVideoButton" key="client-hours-on-server-hero" />
    </div>
    <div class="copy">
     <ProjectSvg type="logo" />
     <MessHtml v-if="copy" :html="copy" class="body-lg" />
     <ProjectButton v-if="button" :label="button.label" :link="button.url" :title="button.title" class="white" />
    </div>
   </div>
   <MessHtml v-if="verticalText" :html="verticalText" class="decorator utility" />
  </div>
 </section>
</template>

<style lang="scss">
 section.block-hero {
  --hero-reveal: 750ms ease-in-out;
  @keyframes slideFromLeft {
   from {
    transform: translateX(100%);
    clip-path: inset(100% 0 0 0);
   }
   to {
    transform: translateX(0);
    clip-path: inset(0 0 0 0);
   }
  }

  @keyframes slideFromRight {
   from {
    transform: translateX(-100%);
    clip-path: inset(100% 0 0 0);
   }
   to {
    transform: translateX(0);
    clip-path: inset(0 0 0 0);
   }
  }

  background-color: var(--gray-100);

  .hero-image {
   width: 100%;
   height: 100%;
  }

  .container {
   position: relative;

   .grid {
    overflow: hidden;
    display: block;
    grid-template-rows: repeat(28, 25px);
    gap: 0;

    @media (min-width: $mobile) {
     display: grid;
    }
   }
  }

  .title {
   grid-column: 1 / span 9;
   grid-row: 1 / span 13;

   margin: 0 0 20px 0;
   z-index: 2;

   font-size: calc(45 / var(--base-fs) * 1rem);

   @media (min-width: $phone) {
    font-size: calc(50 / var(--base-fs) * 1rem);
   }

   @media (min-width: $mobile) {
    font-size: calc(80 / var(--base-fs) * 1rem);
    text-indent: 25%;
   }

   @media (min-width: $tablet) {
    font-size: calc(100 / var(--base-fs) * 1rem);
   }

   @media (min-width: $desktop) {
    font-size: calc(130 / var(--base-fs) * 1rem);
   }
  }

  .media {
   aspect-ratio: 33 / 23;
   grid-column: 4 / span 7;
   grid-row: 8 / span 21;
   position: relative;

   display: flex;
   align-items: flex-end;

   .mess-image {
    position: absolute;
   }

   .video-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    // aspect-ratio: 16 / 9;
   }

   .status-message {
    position: absolute;
    bottom: 1.5rem;
    right: 1rem;
   }

   @media (min-width: $tablet) {
    grid-column: 6 / span 7;
    aspect-ratio: unset;
   }
  }

  .copy {
   grid-column: 1 / span 3;
   grid-row: 16 / span 13;
   background-color: var(--red);
   overflow: hidden;
   padding: 2rem;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: flex-start;
   color: white;

   @media (min-width: $tablet) {
    grid-column: 3 / span 3;
   }

   .mess-svg {
    margin-bottom: 1rem;
   }

   .project-button {
    margin-top: 1em;
   }
  }

  .decorator {
   width: 100%;
   margin-top: 20px;

   @media (min-width: $tablet) {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 30px;
    transform: rotate(-90deg);
    transform-origin: top left;
   }
  }

  @media (min-width: $tablet) {
   .server-v-aos {
    .media {
     transform: translateX(100%);
     clip-path: inset(100% 0 0 0);
    }

    .copy {
     transform: translateX(-100%);
     clip-path: inset(100% 0 0 0);
    }

    &.v-aos-entered {
     .media {
      animation: slideFromLeft var(--hero-reveal);
      animation-fill-mode: forwards;
     }

     .copy {
      animation: slideFromRight var(--hero-reveal);
      animation-fill-mode: forwards;
     }
    }
   }
  }
 }
</style>
